.ql-container {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ql-toolbar {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ql-editor {
  color: #e9ecef;
  max-height: 150px;
}

.ql-editor.ql-blank::before {
  color: #e9ecef;
  opacity: 0.6;
  content: attr(data-placeholder);
  font-style: normal;
  pointer-events: none;
  position: absolute;
}

.ql-container.ql-snow {
  border: 1px solid #959595;
}

.ql-toolbar.ql-snow {
  border: 1px solid #959595;
  box-sizing: border-box;
  padding: 8px;
}

.ql-snow .ql-stroke {
  stroke: #e9ecef;
}
.ql-snow .ql-stroke-miter {
  stroke: #e9ecef;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #e9ecef;
}

.ql-snow .ql-picker .ql-picker-label {
  color: #e9ecef;
}
